import { CSSProperties } from "react";
import { LoadingOutlined } from "@ant-design/icons";

interface ButtonProps {
  content?: React.ReactNode;
  disabled?: boolean;
  loading?: boolean;
  plain?: boolean;
  danger?: boolean;
  onClick?(): void;
  style?: CSSProperties;
}

export function Button(props: ButtonProps) {
  return (
    <div
      style={{ ...props.style }}
      className={
        "button" +
        (props.disabled ? " button__disabled" : "") +
        (props.plain ? " button__plain" : "") +
        (props.danger ? " button__danger" : "")
      }
      onClick={() =>
        !props.disabled && !props.loading && props.onClick && props.onClick()
      }
    >
      <div
        style={{
          visibility: props.loading ? "hidden" : undefined,
          height: props.loading ? 0 : undefined,
        }}
      >
        {props.content}
      </div>
      {props.loading && <LoadingOutlined />}
    </div>
  );
}
