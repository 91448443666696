import { App } from "./models";

export const DOMAIN = "ckal.dk";

export const LF_CREDENTIALS = "ckal_credentials";
export const AWS_CLIENT_ID = "6nki0f24aj9hrvluekbmkea631";

export const APPS: App[] = [
  {
    name: "Gokart",
    description:
      "Register and track your performance on Denmark's gokart tracks and see how you compare to others",
    url: `https://gokart.${DOMAIN}`,
    imagePath: "",
  },
];
