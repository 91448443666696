interface HeaderProps {
  text: string;
}

export function Header(props: HeaderProps) {
  return (
    <div
      style={{
        fontSize: "28px",
        textAlign: "center",
        width: "100%",
        fontWeight: 400,
        marginBottom: 24,
      }}
    >
      {props.text}
    </div>
  );
}
