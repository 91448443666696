import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "../components/button";
import { FormContainer } from "../components/formContainer";
import { Header } from "../components/header";
import { Input } from "../components/input";
import { getUserInfo, updateProfile } from "../lib/authenticationHandler";
import { RedirectContext } from "../lib/contexts";
import { ImagePicker } from "../components/ImagePicker";
import { getAccessToken } from "../lib/credentialsHandler";
import { useAvatarImage } from "../lib/useAvatarImage";

export function EditProfilePage() {
  const [email, setEmail] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [nickname, setNickname] = useState("");
  const [isUpdatingProfile, setIsUpdatingProfile] = useState(false);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [changedImage, setChangedImage] = useState(false);

  const redirectUrl = useContext(RedirectContext);
  const history = useHistory();

  useEffect(() => {
    getUserInfo().then((userInfo) => {
      setEmail(userInfo.email);
      setFirstname(userInfo.firstname);
      setLastname(userInfo.lastname);
      setNickname(userInfo.nickname);
    });
  }, []);

  useAvatarImage(email, setSelectedImage);

  async function uploadImage(file: File) {
    const formData = new FormData();

    formData.append("file", file);

    const result = await fetch("https://api.ckal.dk/user/image", {
      method: "POST",
      headers: { Authorization: await getAccessToken() },
      body: formData,
    });

    if (!result.ok) {
      alert("An error occurred uploading image");
      return;
    }
  }

  return (
    <FormContainer>
      <Header text="Edit Profile" />
      <ImagePicker
        placeholderLetters={
          (firstname[0]?.toUpperCase() || "") +
          (lastname[0]?.toUpperCase() || "")
        }
        selectedImage={selectedImage}
        onImageSelect={(image) => {
          setSelectedImage(image);
          setChangedImage(true);
        }}
      />
      <Input
        label="Firstname"
        value={firstname}
        onChange={setFirstname}
        style={{ marginTop: 24 }}
      />
      <Input
        label="Lastname"
        value={lastname}
        onChange={setLastname}
        style={{ marginTop: 24 }}
      />
      <Input
        label="Nickname"
        value={nickname}
        onChange={setNickname}
        style={{ marginTop: 24 }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: 36,
          width: "100%",
        }}
      >
        <Button
          content="Cancel"
          onClick={() => history.push("/home")}
          plain
          style={{ marginRight: 16 }}
        />
        <Button
          content="Update"
          loading={isUpdatingProfile}
          onClick={async () => {
            setIsUpdatingProfile(true);
            try {
              await updateProfile(firstname, lastname, nickname);
              if (changedImage && selectedImage) {
                await uploadImage(selectedImage);
              }

              if (redirectUrl) {
                window.location.href = redirectUrl + `?email=${email}`;
              } else {
                history.push("/home");
              }
            } catch (error) {
              alert(error);
            }
            setIsUpdatingProfile(false);
          }}
        />
      </div>
    </FormContainer>
  );
}
