import yomiIcon from "../lib/images/yomi-icon.png";
import workStatusIcon from "../lib/images/workstatus-icon.png";
import laerNuDKIcon from "../lib/images/laernudk-icon.png";
import { AppName } from "../lib/models";

interface ImageIconProps {
  app: AppName;
}

export function ImageIcon(props: ImageIconProps) {
  return (
    <div
      style={{
        height: 48,
        width: 48,
        overflow: "hidden",
        borderRadius: 12,
        boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
      }}
    >
      <img
        src={
          props.app === "Yomi"
            ? yomiIcon
            : props.app === "WorkStatus"
            ? workStatusIcon
            : laerNuDKIcon
        }
        style={{ height: "48px", width: "48px" }}
        alt=""
      />
    </div>
  );
}
