import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Button } from "../components/button";
import { FormContainer } from "../components/formContainer";
import { Header } from "../components/header";
import { PinInput } from "../components/pinInput";
import { ResendButton } from "../components/resendLink";
import {
  confirmmSignUp,
  resendConfirmationCode,
} from "../lib/authenticationHandler";
import { FormDetailsContext, RedirectContext } from "../lib/contexts";

export function ConfirmPage() {
  const [confirmationCode, setConfirmationCode] = useState("");

  const [isConfirming, setIsConfirming] = useState(false);

  const redirectUrl = useContext(RedirectContext);
  const { email } = useContext(FormDetailsContext);

  const history = useHistory();

  useEffect(() => {
    if (!email) {
      history.push("/sign-up");
    }
  }, [email, history]);

  async function handleConfirmAccount() {
    setIsConfirming(true);

    try {
      await confirmmSignUp(email, confirmationCode);
      if (redirectUrl) {
        window.location.href = redirectUrl + `?email=${email}`;
      } else {
        history.push("/login");
      }
    } catch (error) {
      alert((error as Error).message);
    }

    setIsConfirming(false);
  }

  return (
    <FormContainer>
      <Header text="Verify account" />
      <div style={{ margin: "0 48px" }}>
        A verification code has been sent to {email}.
        <ResendButton onClick={() => resendConfirmationCode(email)} />
      </div>
      <div style={{ marginTop: "36px" }}>
        <PinInput onPinChange={setConfirmationCode} />
      </div>
      <div>
        <Button content="Back" plain onClick={history.goBack} />
        <Button
          content="Confirm Account"
          onClick={handleConfirmAccount}
          loading={isConfirming}
          disabled={confirmationCode.length !== 6}
          style={{ marginTop: "36px", marginLeft: "24px" }}
        />
      </div>
    </FormContainer>
  );
}
