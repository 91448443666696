import { useContext, useRef, useState } from "react";
import { useHistory } from "react-router";
import { Button } from "../components/button";
import { FormContainer } from "../components/formContainer";
import { Header } from "../components/header";
import { Input } from "../components/input";
import { PinInput } from "../components/pinInput";
import { ResendButton } from "../components/resendLink";
import {
  confirmForgotPassword,
  forgotPassword,
} from "../lib/authenticationHandler";
import { FormDetailsContext, RedirectContext } from "../lib/contexts";

export function ForgotPasswordPage() {
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [hasSentCode, setHasSentCode] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState("");
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordRepeated, setPasswordRepeated] = useState("");

  const redirectUrl = useContext(RedirectContext);
  const { email, setEmail } = useContext(FormDetailsContext);

  const passwordRepeatedRef = useRef<HTMLInputElement>(null);

  const history = useHistory();

  return (
    <FormContainer>
      <Header text="Forgot password" />

      {!hasSentCode ? (
        <Input
          label="Email Address"
          value={email}
          onChange={setEmail}
          style={{ marginTop: "24px" }}
        />
      ) : (
        <>
          <div style={{ margin: "0 24px 24px 24px", textAlign: "center" }}>
            A recovery code has been sent to {email}.
            <ResendButton onClick={() => forgotPassword(email)} />
          </div>
          <PinInput onPinChange={setConfirmationCode} />
          <Input
            label="New password (min. 6 characters)"
            style={{ marginTop: "24px" }}
            value={password}
            type="password"
            onChange={setPassword}
            onPressEnter={() => passwordRepeatedRef.current?.select()}
          />
          <Input
            label="Repeat new password"
            value={passwordRepeated}
            reference={passwordRepeatedRef}
            onChange={setPasswordRepeated}
            type="password"
            style={{ marginTop: "24px" }}
          />
        </>
      )}
      <div style={{ marginTop: "36px" }}>
        <Button
          content="Back"
          plain
          style={{ marginRight: "24px" }}
          onClick={history.goBack}
        />
        {!hasSentCode ? (
          <Button
            content="Next"
            disabled={!email}
            loading={isSendingCode}
            onClick={async () => {
              setIsSendingCode(true);
              try {
                await forgotPassword(email);
                setHasSentCode(true);
              } catch (error: any) {
                alert(error.message);
              }
              setIsSendingCode(false);
            }}
          />
        ) : (
          <Button
            content="Reset password"
            onClick={async () => {
              setIsResettingPassword(true);
              try {
                await confirmForgotPassword(confirmationCode, email, password);
                if (redirectUrl) {
                  window.location.href = redirectUrl + `?email=${email}`;
                } else {
                  history.push("/login");
                }
              } catch (error: any) {
                alert(error.message);
              }
              setIsResettingPassword(false);
            }}
            loading={isResettingPassword}
            disabled={
              confirmationCode.length !== 6 ||
              password.length < 5 ||
              password !== passwordRepeated
            }
          />
        )}
      </div>
    </FormContainer>
  );
}
