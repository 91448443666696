import { useHistory } from "react-router-dom";
import { Button } from "../components/button";
import { FormContainer } from "../components/formContainer";
import { Header } from "../components/header";
import { InstallAppLine } from "../components/installAppLine";

export function InstallAppsPage() {
  const history = useHistory();

  return (
    <FormContainer>
      <Header text="iOS App installation" />
      <div
        style={{
          marginBottom: 36,
          textAlign: "center",
          fontStyle: "italic",
          maxWidth: 400,
        }}
      >
        <span>
          If you haven't already registered for installation of these
          applications, then you can do so
        </span>
        <a
          href="https://expo.dev/register-device/afb6feaf-8f0c-4592-b777-46e98f25c5cc"
          style={{ marginLeft: 5 }}
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>
        <span>.</span>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "min-content auto min-content",
          alignItems: "center",
          columnGap: 16,
          rowGap: 16,
        }}
      >
        <InstallAppLine app="Yomi" />
        <InstallAppLine app="WorkStatus" />
        <InstallAppLine app="LaerNuDK" />
      </div>
      <Button
        content="Back"
        onClick={() => history.push("/home")}
        plain
        style={{ marginTop: 48 }}
      />
    </FormContainer>
  );
}
