interface FormContainerProps {
  children?: React.ReactNode;
}

export function FormContainer(props: FormContainerProps) {
  return (
    <div
      style={{ display: "flex", justifyContent: "center", marginTop: "24px" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {props.children}
      </div>
    </div>
  );
}
