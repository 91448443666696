import { useContext, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router";
import { Button } from "../components/button";
import { FormContainer } from "../components/formContainer";
import { Header } from "../components/header";
import { Input } from "../components/input";
import { login, resendConfirmationCode } from "../lib/authenticationHandler";
import { FormDetailsContext } from "../lib/contexts";

export function LoginPage() {
  const [isLogginIn, setIsLoggingIn] = useState(false);
  const [password, setPassword] = useState("");

  const history = useHistory();

  const { email, setEmail } = useContext(FormDetailsContext);

  const passwordInputRef = useRef<HTMLInputElement>(null);

  const loginDisabled = useMemo(
    () => !email || password.length < 6,
    [email, password]
  );

  async function handleLogin() {
    setIsLoggingIn(true);
    try {
      await login(email, password);
      history.push("/home");
    } catch (error: any) {
      if (error.name === "UserNotConfirmedException") {
        try {
          await resendConfirmationCode(email);
          history.push("/confirm");
        } catch (error: any) {
          if (error.name === "LimitExceededException") {
            alert(error.message);
          }
        }
      } else {
        alert(error.message);
      }
    }
    setIsLoggingIn(false);
  }

  return (
    <FormContainer>
      <Header text="Login" />
      <Input
        label="Email Address"
        value={email}
        onChange={setEmail}
        type="email"
        onPressEnter={() => passwordInputRef.current?.select()}
      />
      <Input
        label="Password"
        value={password}
        onChange={setPassword}
        reference={passwordInputRef}
        style={{ marginTop: "24px" }}
        type="password"
        onPressEnter={() => !loginDisabled && handleLogin()}
      />
      <div
        style={{ width: "100%", textAlign: "end", marginTop: "8px" }}
        className="link"
        onClick={() => history.push("/forgot-password")}
      >
        Forgot password?
      </div>
      <Button
        content="Login"
        disabled={loginDisabled}
        onClick={handleLogin}
        loading={isLogginIn}
        style={{ marginTop: "36px" }}
      />
      <div
        style={{ marginTop: "36px" }}
        onClick={() => history.push("/sign-up")}
        className="link"
      >
        Sign up
      </div>
    </FormContainer>
  );
}
