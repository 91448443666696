import { useEffect, useRef, useState } from "react";
import { PinInputBox } from "./pinInputBox";

interface PinInputProps {
  onPinChange(pin: string): void;
}

export function PinInput(props: PinInputProps) {
  const [confirmationPin, setConfirmationPin] = useState<string[]>([
    "",
    "",
    "",
    "",
    "",
    "",
  ]);

  const input1ref = useRef<HTMLInputElement>(null);
  const input2ref = useRef<HTMLInputElement>(null);
  const input3ref = useRef<HTMLInputElement>(null);
  const input4ref = useRef<HTMLInputElement>(null);
  const input5ref = useRef<HTMLInputElement>(null);
  const input6ref = useRef<HTMLInputElement>(null);

  const { onPinChange } = props;

  useEffect(() => {
    const pin = confirmationPin.join("");
    if (/^[0-9]+$/.test(pin)) {
      onPinChange(pin.toString());
    } else {
      onPinChange("-1");
    }
  }, [confirmationPin, onPinChange]);

  function updateConfirmationPin(n: string, position: number) {
    const newPin = [...confirmationPin];
    newPin[position] = n;
    setConfirmationPin(newPin);
  }

  function bulkUpdateConfirmationPin(input: string, position: number) {
    const newPin = [...confirmationPin];

    for (let i = 0; i < input.length; i++) {
      newPin[position + i] = input.charAt(i);
    }

    setConfirmationPin(newPin.slice(0, 6));

    const selectionIndex = Math.min(position + input.length - 1, 5);

    if (selectionIndex === 0) input1ref.current?.select();
    if (selectionIndex === 1) input2ref.current?.select();
    if (selectionIndex === 2) input3ref.current?.select();
    if (selectionIndex === 3) input4ref.current?.select();
    if (selectionIndex === 4) input5ref.current?.select();
    if (selectionIndex === 5) input6ref.current?.select();
  }

  return (
    <div style={{ display: "flex" }}>
      <PinInputBox
        reference={input1ref}
        nextRef={input2ref}
        value={confirmationPin[0]}
        onChange={(n) => updateConfirmationPin(n, 0)}
        onExcessInput={(input) => bulkUpdateConfirmationPin(input, 0)}
      />
      <PinInputBox
        reference={input2ref}
        previousRef={input1ref}
        nextRef={input3ref}
        value={confirmationPin[1]}
        onChange={(n) => updateConfirmationPin(n, 1)}
        onExcessInput={(input) => bulkUpdateConfirmationPin(input, 1)}
      />
      <PinInputBox
        reference={input3ref}
        previousRef={input2ref}
        nextRef={input4ref}
        value={confirmationPin[2]}
        onChange={(n) => updateConfirmationPin(n, 2)}
        onExcessInput={(input) => bulkUpdateConfirmationPin(input, 2)}
      />
      <PinInputBox
        reference={input4ref}
        previousRef={input3ref}
        nextRef={input5ref}
        value={confirmationPin[3]}
        onChange={(n) => updateConfirmationPin(n, 3)}
        onExcessInput={(input) => bulkUpdateConfirmationPin(input, 3)}
      />
      <PinInputBox
        reference={input5ref}
        previousRef={input4ref}
        nextRef={input6ref}
        value={confirmationPin[4]}
        onChange={(n) => updateConfirmationPin(n, 4)}
        onExcessInput={(input) => bulkUpdateConfirmationPin(input, 4)}
      />
      <PinInputBox
        reference={input6ref}
        previousRef={input5ref}
        value={confirmationPin[5]}
        onChange={(n) => updateConfirmationPin(n, 5)}
        onExcessInput={(input) => bulkUpdateConfirmationPin(input, 5)}
      />
    </div>
  );
}
