import { useEffect } from "react";

export function useAvatarImage(
  email: string,
  setAvatarImage: (file: File) => void
) {
  useEffect(() => {
    if (!email) {
      return;
    }

    fetch(
      `https://ckal.s3.eu-west-1.amazonaws.com/profile-pictures/${email}/avatar-128.png`
    )
      .then((res) => {
        if (!res.ok) {
          return;
        }

        return res.blob();
      })
      .then((blob) => {
        if (!blob) {
          return;
        }

        setAvatarImage(
          new File([blob], "downloaded.jpg", { type: "image/jpeg" })
        );
      });
  }, [email, setAvatarImage]);
}
