import React, { useRef, useState } from "react";
import { useHistory } from "react-router";
import { Button } from "../components/button";
import { FormContainer } from "../components/formContainer";
import { Header } from "../components/header";
import { Input } from "../components/input";
import { changePassword } from "../lib/authenticationHandler";

export function ChangePasswordPage() {
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passwordRepeated, setPasswordRepeated] = useState("");
  const [isChangingPassword, setIsChangingPassword] = useState(false);

  const history = useHistory();

  const passwordInputRef = useRef<HTMLInputElement>(null);
  const passwordRepeatedInputRef = useRef<HTMLInputElement>(null);

  return (
    <FormContainer>
      <Header text="Change Password" />
      <Input
        label="Current password"
        value={oldPassword}
        onChange={setOldPassword}
        type="password"
        onPressEnter={() => passwordInputRef.current?.select()}
      />
      <Input
        label="New password (min. 6 characters)"
        value={password}
        onChange={setPassword}
        reference={passwordInputRef}
        type="password"
        onPressEnter={() => passwordRepeatedInputRef.current?.select()}
        style={{ marginTop: "24px" }}
      />
      <Input
        label="Repeat new password"
        value={passwordRepeated}
        reference={passwordRepeatedInputRef}
        onChange={setPasswordRepeated}
        type="password"
        style={{ marginTop: "24px" }}
      />

      <div style={{ marginTop: "36px" }}>
        <Button content="Back" plain onClick={history.goBack} />
        <Button
          content="Change Password"
          style={{ marginLeft: "24px" }}
          disabled={
            !oldPassword || password.length < 6 || password !== passwordRepeated
          }
          loading={isChangingPassword}
          onClick={async () => {
            setIsChangingPassword(true);

            try {
              await changePassword(oldPassword, password);
              history.push("/login");
            } catch (error) {
              alert(error.message);
            }

            setIsChangingPassword(false);
          }}
        />
      </div>
    </FormContainer>
  );
}
