import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { CSSProperties, useState } from "react";

interface InputProps {
  label?: string;
  style?: CSSProperties;
  type?: string;
  value?: string;
  reference?: React.LegacyRef<HTMLInputElement>;
  readonly?: boolean;
  onPressEnter?(): void;
  onChange?(value: string): void;
}

const EyeIconStyles: CSSProperties = {
  position: "absolute",
  top: "50%",
  right: "16px",
  transform: "translate(0,-50%)",
  fontSize: "20px",
};

export function Input(props: InputProps) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div style={{ ...props.style }}>
      {props.label && (
        <div style={{ fontWeight: "bold", marginBottom: "8px" }}>
          {props.label}
        </div>
      )}
      <div style={{ position: "relative" }}>
        {props.readonly ? (
          <div style={{ fontSize: "20px" }}>{props.value}</div>
        ) : (
          <input
            type={showPassword ? undefined : props.type}
            value={props.value}
            ref={props.reference}
            onChange={(e) => props.onChange && props.onChange(e.target.value)}
            className="input"
            onKeyPress={(e) => {
              if (e.key === "Enter" && props.onPressEnter) {
                props.onPressEnter();
              }
            }}
          />
        )}

        {props.type === "password" &&
          (showPassword ? (
            <EyeInvisibleOutlined
              style={EyeIconStyles}
              onClick={() => setShowPassword(false)}
            />
          ) : (
            <EyeOutlined
              style={EyeIconStyles}
              onClick={() => setShowPassword(true)}
            />
          ))}
      </div>
    </div>
  );
}
