import { CSSProperties, useRef } from "react";
import { MdEdit } from "react-icons/md";

interface ImagePickerProps {
  placeholderLetters: string;
  selectedImage: File | null;
  onImageSelect?(image: File): void;
  readOnly?: boolean;
  style?: CSSProperties;
}

export function ImagePicker(props: ImagePickerProps) {
  function onImageChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (!props.onImageSelect) {
      return;
    }

    if (event.target.files && event.target.files[0]) {
      props.onImageSelect(event.target.files[0]);
    }
  }

  const inputRef = useRef<HTMLInputElement | null>(null);

  return (
    <div style={props.style}>
      <div style={{ display: "grid", gridTemplateColumns: "1fr auto 1fr" }}>
        <div />
        {props.selectedImage ? (
          <img
            src={URL.createObjectURL(props.selectedImage)}
            alt=""
            style={{
              width: 128,
              height: 128,
              borderRadius: "50%",
              objectFit: "cover",
              objectPosition: "center",
            }}
          />
        ) : (
          <div
            style={{
              height: 128,
              width: 128,
              background: "#51b1ff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
            }}
          >
            <div style={{ fontSize: 48, fontWeight: "bold", color: "white" }}>
              {props.placeholderLetters}
            </div>
          </div>
        )}
        {!props.readOnly && (
          <>
            <input
              type="file"
              accept="image/*"
              ref={inputRef}
              onChange={onImageChange}
              style={{ display: "none" }}
            />
            <MdEdit
              onClick={() => inputRef.current?.click()}
              style={{
                cursor: "pointer",
                fontSize: 24,
                alignSelf: "end",
              }}
            />
          </>
        )}
      </div>
    </div>
  );
}
