interface PinInputBoxProps {
  reference: React.RefObject<HTMLInputElement>;
  previousRef?: React.RefObject<HTMLInputElement>;
  nextRef?: React.RefObject<HTMLInputElement>;
  value: string;
  onChange(number: string): void;
  onExcessInput(input: string): void;
}

export function PinInputBox(props: PinInputBoxProps) {
  return (
    <input
      className="input input__pin"
      ref={props.reference}
      value={props.value}
      type="tel"
      onKeyDown={(e) => {
        if (e.key === "Backspace") {
          props.onChange("");
          setTimeout(() => props.previousRef?.current?.select(), 1);
        }
      }}
      onChange={(e) => {
        const input = e.target.value;

        if (!/^[0-9]+$/.test(input)) {
          return;
        }

        if (input.length > 1) {
          if (!props.nextRef) {
            props.onChange(input.slice(-1));
          } else {
            props.onExcessInput(input);
          }
        } else {
          props.onChange(input);
          input === ""
            ? props.previousRef?.current?.select()
            : props.nextRef?.current?.select();
        }
      }}
    />
  );
}
