import { useEffect, useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { getUserInfo } from "./lib/authenticationHandler";
import { FormDetailsContext, RedirectContext } from "./lib/contexts";
import { forceRefresh } from "./lib/functions";
import { ChangePasswordPage } from "./pages/changePasswordPage";
import { ConfirmPage } from "./pages/confirmPage";
import { EditProfilePage } from "./pages/editProfilePage";
import { ForgotPasswordPage } from "./pages/forgotPasswordPage";
import { HomePage } from "./pages/homePage";
import { InstallAppsPage } from "./pages/installAppsPage";
import { LoginPage } from "./pages/loginPage";
import { SignUpPage } from "./pages/signUpPage";

function App() {
  const [email, setEmail] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [redirectUrl, setRedirectUrl] = useState("");

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const redirectUrl = new URLSearchParams(location.search).get("redirect");
    if (redirectUrl) {
      setRedirectUrl(redirectUrl);
    }
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    (async () => {
      try {
        await getUserInfo();
        if (
          ["/sign-up", "/forgot-password", "/confirm"].includes(
            location.pathname
          )
        ) {
          history.push("/home");
        }
      } catch {}
    })();
  }, [history, location]);

  function clearFormDetails() {
    setEmail("");
    setFirstname("");
    setLastname("");
  }

  return (
    <div>
      <div
        style={{
          marginBottom: "192px",
        }}
      >
        <div className="header" style={{ backgroundImage: "url(/ok.png)" }}>
          CKAL
        </div>
        <FormDetailsContext.Provider
          value={{
            email,
            setEmail,
            firstname,
            setFirstname,
            lastname,
            setLastname,
            clearFormDetails,
          }}
        >
          <RedirectContext.Provider value={redirectUrl}>
            <Switch>
              <Route exact path="/sign-up" component={SignUpPage} />
              <Route exact path="/login" component={LoginPage} />
              <Route exact path="/confirm" component={ConfirmPage} />
              <Route exact path="/home" component={HomePage} />
              <Route exact path="/edit-profile" component={EditProfilePage} />
              <Route exact path="/apps" component={InstallAppsPage} />
              <Route
                exact
                path="/forgot-password"
                component={ForgotPasswordPage}
              />
              <Route
                exact
                path="/change-password"
                component={ChangePasswordPage}
              />
              <Route render={() => <Redirect to="/sign-up" />} />
            </Switch>
          </RedirectContext.Provider>
        </FormDetailsContext.Provider>
      </div>
      <div
        style={{
          textAlign: "center",
          margin: "32px",
          color: "#aaaaaa",
        }}
        onClick={forceRefresh}
      >
        {process.env.REACT_APP_VERSION}
      </div>
    </div>
  );
}

export default App;
