import { createContext } from "react";

export const FormDetailsContext = createContext<{
  email: string;
  setEmail(email: string): void;
  firstname: string;
  setFirstname(firstname: string): void;
  lastname: string;
  setLastname(lastname: string): void;
  clearFormDetails(): void;
}>({
  email: "",
  setEmail: () => {},
  firstname: "",
  setFirstname: () => {},
  lastname: "",
  setLastname: () => {},
  clearFormDetails: () => {},
});

export const RedirectContext = createContext<string>("");
