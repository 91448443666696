import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "../components/button";
import { deleteUser, getUserInfo } from "../lib/authenticationHandler";
import { UserInfo } from "../lib/models";
import localForage from "localforage";
import { LF_CREDENTIALS } from "../lib/definitions";
import { FormDetailsContext } from "../lib/contexts";
import { Input } from "../components/input";
import { getAccessToken } from "../lib/credentialsHandler";
import { ImagePicker } from "../components/ImagePicker";
import { useAvatarImage } from "../lib/useAvatarImage";

export function HomePage() {
  const [isDeletingUser, setIsDeletingUser] = useState(false);
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [isMinecraftServerRunning, setIsMinecraftServerRunning] =
    useState(false);
  const [isMinecraftServerRunningSimon, setIsMinecraftServerRunningSimon] =
    useState(false);
  const [avatar, setAvatar] = useState<File | null>(null);

  const { clearFormDetails } = useContext(FormDetailsContext);

  const history = useHistory();

  useAvatarImage(userInfo?.email || "", setAvatar);

  useEffect(() => {
    getUserInfo()
      .then(setUserInfo)
      .catch(() => history.push("/login"));
  }, [history]);

  useEffect(() => {
    updateMinecraftServerStatus();
    updateMinecraftServerStatus("simon");
  }, []);

  function startMinecraftServer(postfix?: string) {
    fetch(`https://api.ckal.dk/minecraft/open${postfix || ""}`, {
      method: "POST",
    }).then((res) => {
      if (res.ok) {
        updateMinecraftServerStatus(postfix);
      }
    });
  }

  function updateMinecraftServerStatus(postfix?: string) {
    fetch(`https://api.ckal.dk/minecraft/status${postfix || ""}`)
      .then((res) => {
        if (!res.ok) throw Error();
        return res;
      })
      .then((res) => res.json())
      .then((status) => {
        if (postfix === "simon") {
          setIsMinecraftServerRunningSimon(status);
        } else {
          setIsMinecraftServerRunning(status);
        }
      })
      .catch(console.log);
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        padding: "0 36px",
      }}
    >
      <div
        style={{
          width: "400px",
          marginTop: "24px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            fontSize: "32px",
            width: "100%",
            textAlign: "center",
          }}
        >
          Hello, {userInfo?.firstname}!
        </div>
        <ImagePicker
          readOnly
          selectedImage={avatar}
          placeholderLetters={
            (userInfo?.firstname[0].toUpperCase() || "") +
              userInfo?.lastname[0].toUpperCase() || ""
          }
          style={{ marginTop: 12 }}
        />
        <div
          style={{
            width: "100%",
          }}
        >
          <div
            style={{
              margin: "24px 0 8px 0",
              fontWeight: "bold",
              fontSize: "24px",
              display: "flex",
              alignItems: "center",
            }}
          >
            Profile details
          </div>
          <Input
            label="Email address"
            value={userInfo?.email}
            style={{ marginTop: "24px" }}
            readonly
          />
          <Input
            label="Name"
            value={userInfo?.firstname + " " + userInfo?.lastname}
            style={{ marginTop: "24px" }}
            readonly
          />
          <Input
            label="Nickname"
            style={{ marginTop: "24px" }}
            value={userInfo?.nickname || "Not specified"}
            readonly
          />
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr",
            gap: "24px",
            marginTop: "48px",
            justifyItems: "center",
          }}
        >
          <Button
            content="Edit profile"
            plain
            onClick={() => history.push("/edit-profile")}
            style={{ width: 137 }}
          />
          <Button
            content="Change password"
            plain
            onClick={() => history.push("/change-password")}
            style={{ width: 137 }}
          />
          <Button
            content="Sign out"
            plain
            onClick={() => {
              localForage.removeItem(LF_CREDENTIALS, () =>
                history.push("/login")
              );
            }}
            style={{ width: 137 }}
          />
          <div />
          <Button
            content="Delete account"
            danger
            loading={isDeletingUser}
            onClick={async () => {
              if (
                window.confirm("Are you sure you want to delete your account?")
              ) {
                setIsDeletingUser(true);
                try {
                  await deleteUser();
                  clearFormDetails();
                  history.push("/sign-up");
                } catch (error) {
                  alert(error);
                }
                setIsDeletingUser(false);
              }
            }}
            style={{ width: 137 }}
          />
          <div />
          <Button
            content="Copy token"
            onClick={() =>
              getAccessToken().then((val) => {
                navigator.clipboard
                  .writeText(val)
                  .then(() => alert("Access token copied"));
              })
            }
            style={{ width: 137 }}
          />
          <div />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{ marginBottom: 12, textAlign: "center" }}>
              {isMinecraftServerRunning
                ? "Minecraft server is online!"
                : "Minecraft server is offline"}
            </div>
            <Button
              content="Start Minecraft server"
              disabled={isMinecraftServerRunning}
              style={{
                background: isMinecraftServerRunning ? undefined : "#34ba2a",
                borderColor: isMinecraftServerRunning ? undefined : "#34ba2a",
              }}
              onClick={() => startMinecraftServer()}
            />
            <div
              style={{
                marginLeft: 8,
                color: "#4285f4",
                fontWeight: "bold",
                marginTop: 16,
                cursor: "pointer",
              }}
              onClick={() => updateMinecraftServerStatus()}
            >
              Refresh
            </div>
          </div>
          <div />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{ marginBottom: 6, textAlign: "center" }}>
              {isMinecraftServerRunningSimon
                ? "Other Minecraft server is online!"
                : "Other Minecraft server is offline"}
            </div>
            <div style={{ marginBottom: 12, color: "darkgray" }}>
              Address: ckal.dk:25566
            </div>
            <Button
              content="Start other Minecraft server"
              disabled={isMinecraftServerRunningSimon}
              style={{
                background: isMinecraftServerRunningSimon
                  ? undefined
                  : "#34ba2a",
                borderColor: isMinecraftServerRunningSimon
                  ? undefined
                  : "#34ba2a",
              }}
              onClick={() => startMinecraftServer("simon")}
            />
            <div
              style={{
                marginLeft: 8,
                color: "#4285f4",
                fontWeight: "bold",
                marginTop: 16,
                cursor: "pointer",
              }}
              onClick={() => updateMinecraftServerStatus("simon")}
            >
              Refresh
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
