import { AppName } from "../lib/models";
import { Button } from "./button";
import { ImageIcon } from "./imageIcon";

interface InstallAppLineProps {
  app: AppName;
}

export function InstallAppLine(props: InstallAppLineProps) {
  return (
    <>
      <ImageIcon app={props.app} />
      <div style={{ fontWeight: "bold" }}>{props.app}</div>
      <Button
        content="Install"
        onClick={() =>
          (window.location.href = `itms-services://?action=download-manifest&url=https://ckal-apps.s3.eu-west-1.amazonaws.com/${props.app}.plist`)
        }
      />
    </>
  );
}
