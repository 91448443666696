import { useState } from "react";

interface ResendButtonProps {
  onClick(): void;
}

export function ResendButton(props: ResendButtonProps) {
  const [showOkMessage, setShowOkMessage] = useState(false);

  return (
    <span
      className={showOkMessage ? undefined : "link"}
      style={{
        color: showOkMessage ? undefined : "#4285f4",
        marginLeft: "8px",
      }}
      onClick={() => {
        if (!showOkMessage) {
          props.onClick();
          setShowOkMessage(true);
          setTimeout(() => setShowOkMessage(false), 1500);
        }
      }}
    >
      {showOkMessage ? "Code sent!" : "Resend"}
    </span>
  );
}
