import { useContext, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router";
import { Button } from "../components/button";
import { FormContainer } from "../components/formContainer";
import { Header } from "../components/header";
import { Input } from "../components/input";
import { initiateSignUp } from "../lib/authenticationHandler";
import { FormDetailsContext } from "../lib/contexts";

export function SignUpPage() {
  const [isInitiatingSignUp, setIsInitiatingSignUp] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordRepeated, setPasswordRepeated] = useState("");

  const history = useHistory();

  const { email, setEmail, firstname, setFirstname, lastname, setLastname } =
    useContext(FormDetailsContext);

  const firstnameInputRef = useRef<HTMLInputElement>(null);
  const lastnameInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const passwordRepeatedInputRef = useRef<HTMLInputElement>(null);

  const initiateSignUpDisabled = useMemo(
    () =>
      !email ||
      !firstname ||
      !lastname ||
      password.length < 6 ||
      password !== passwordRepeated,
    [email, firstname, lastname, password, passwordRepeated]
  );

  async function handleSignUp() {
    setIsInitiatingSignUp(true);
    try {
      await initiateSignUp(email, password, firstname, lastname);
      history.push("/confirm");
    } catch (error: any) {
      alert(error.message);
    }
    setIsInitiatingSignUp(false);
  }

  return (
    <FormContainer>
      <Header text="Sign up" />
      <Input
        label="Email Address"
        value={email}
        onChange={setEmail}
        type="email"
        onPressEnter={() => firstnameInputRef.current?.select()}
      />
      <Input
        label="Firstname"
        value={firstname}
        onChange={setFirstname}
        reference={firstnameInputRef}
        onPressEnter={() => lastnameInputRef.current?.select()}
        style={{ marginTop: "24px" }}
      />
      <Input
        label="Lastname"
        value={lastname}
        onChange={setLastname}
        reference={lastnameInputRef}
        onPressEnter={() => passwordInputRef.current?.select()}
        style={{ marginTop: "24px" }}
      />
      <Input
        label="Password (min. 6 characters)"
        value={password}
        onChange={setPassword}
        style={{ marginTop: "24px" }}
        reference={passwordInputRef}
        onPressEnter={() => passwordRepeatedInputRef.current?.select()}
        type="password"
      />
      <Input
        label="Repeat password"
        value={passwordRepeated}
        onChange={setPasswordRepeated}
        style={{ marginTop: "24px" }}
        reference={passwordRepeatedInputRef}
        onPressEnter={() => {
          if (!initiateSignUpDisabled) {
            handleSignUp();
          }
        }}
        type="password"
      />

      <Button
        content="Sign up"
        disabled={initiateSignUpDisabled}
        onClick={handleSignUp}
        loading={isInitiatingSignUp}
        style={{ marginTop: "36px" }}
      />
      <div
        style={{ marginTop: "36px" }}
        onClick={() => history.push("/login")}
        className="link"
      >
        Already have an account?
      </div>
    </FormContainer>
  );
}
