import * as AWS from "aws-sdk";
import { getAccessToken } from "./credentialsHandler";
import { AWS_CLIENT_ID, LF_CREDENTIALS } from "./definitions";
import { Credentials, UserInfo } from "./models";
import * as localForage from "localforage";

const Cognito = new AWS.CognitoIdentityServiceProvider({ region: "eu-west-1" });

export async function initiateSignUp(
  email: string,
  password: string,
  firstname: string,
  lastname: string
) {
  try {
    await Cognito.signUp({
      ClientId: AWS_CLIENT_ID,
      Username: email,
      Password: password,
      UserAttributes: [
        { Name: "custom:firstname", Value: firstname },
        { Name: "custom:lastname", Value: lastname },
      ],
    }).promise();
  } catch (error) {
    throw error;
  }
}

export async function confirmmSignUp(email: string, confirmationCode: string) {
  await Cognito.confirmSignUp({
    ClientId: AWS_CLIENT_ID,
    Username: email,
    ConfirmationCode: confirmationCode,
  }).promise();
}

export async function resendConfirmationCode(email: string) {
  await Cognito.resendConfirmationCode({
    ClientId: AWS_CLIENT_ID,
    Username: email,
  }).promise();
}

export async function login(email: string, password: string) {
  const response = await Cognito.initiateAuth({
    AuthFlow: "USER_PASSWORD_AUTH",
    ClientId: AWS_CLIENT_ID,
    AuthParameters: { USERNAME: email, PASSWORD: password },
  }).promise();

  if (!response.AuthenticationResult) {
    throw new Error("An unexpected error occurred");
  }

  await localForage.setItem(LF_CREDENTIALS, {
    ...response.AuthenticationResult,
    ExpirationTimestamp: Math.round(
      new Date().getTime() / 1000 + response.AuthenticationResult.ExpiresIn!
    ),
  });
}

export async function refreshCredentials(
  refreshToken: string
): Promise<Credentials> {
  const response = await Cognito.initiateAuth({
    AuthFlow: "REFRESH_TOKEN",
    ClientId: AWS_CLIENT_ID,
    AuthParameters: {
      REFRESH_TOKEN: refreshToken,
    },
  }).promise();

  if (!response.AuthenticationResult) {
    throw new Error("An unexpected error occurred");
  }

  return {
    ...response.AuthenticationResult,
    RefreshToken: refreshToken,
    ExpirationTimestamp: Math.round(
      new Date().getTime() / 1000 + response.AuthenticationResult.ExpiresIn!
    ),
  };
}

export async function getUserInfo(): Promise<UserInfo> {
  const response = await Cognito.getUser({
    AccessToken: await getAccessToken(),
  }).promise();

  return {
    email: response.UserAttributes.find((a) => a.Name === "email")?.Value || "",
    firstname:
      response.UserAttributes.find((a) => a.Name === "custom:firstname")
        ?.Value || "",
    lastname:
      response.UserAttributes.find((a) => a.Name === "custom:lastname")
        ?.Value || "",
    nickname:
      response.UserAttributes.find((a) => a.Name === "custom:nickname")
        ?.Value || "",
  };
}

export async function changePassword(
  previousPassword: string,
  newPassword: string
) {
  await Cognito.changePassword({
    AccessToken: await getAccessToken(),
    PreviousPassword: previousPassword,
    ProposedPassword: newPassword,
  }).promise();
}

export async function forgotPassword(email: string): Promise<string> {
  const result = await Cognito.forgotPassword({
    ClientId: AWS_CLIENT_ID,
    Username: email,
  }).promise();

  if (!result.CodeDeliveryDetails?.Destination) {
    throw new Error("An unexpected error occurred");
  }

  return result.CodeDeliveryDetails.Destination;
}

export async function confirmForgotPassword(
  code: string,
  email: string,
  password: string
) {
  await Cognito.confirmForgotPassword({
    ClientId: AWS_CLIENT_ID,
    Username: email,
    Password: password,
    ConfirmationCode: code,
  }).promise();
}

export async function deleteUser() {
  await Cognito.deleteUser({ AccessToken: await getAccessToken() }).promise();
}

export async function updateProfile(
  firstname: string,
  lastname: string,
  nickname: string
) {
  await Cognito.updateUserAttributes({
    AccessToken: await getAccessToken(),
    UserAttributes: [
      { Name: "custom:firstname", Value: firstname },
      { Name: "custom:lastname", Value: lastname },
      { Name: "custom:nickname", Value: nickname },
    ],
  }).promise();
}
